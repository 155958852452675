<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    @close="colseDialog"
    @submit="submit"
    width="45%"
  >
    <div class="editTitle">基本信息</div>

    <div class="edit-main">
      <el-form
        :model="editForm"
        label-width="80px"
        class="edit-form"
        :rules="rules"
      >
        <el-form-item label="附件" prop="staffPic">
          <el-upload
            class="file-uploader"
            :action="`${uploadUrl}update/GetServerSoftLastVersion/checkAppVersions`"
            :show-file-list="true"
            :before-upload="uploadBefore"
            :auto-upload="true"
            :on-success="uploadSuccess"
          >
            <div class="upload-main">
              <span class="upload-btn">
                <i class="el-icon-upload"></i>上传附件
              </span>
              上传apk包文件
            </div>
          </el-upload>
          <el-upload
            class="file-uploader"
            :action="`${uploadUrl}update/GetServerSoftLastVersion/checkJsonVersion`"
            :show-file-list="true"
            :before-upload="uploadBefore"
            :auto-upload="true"
            :on-success="uploadSuccess"
          >
            <div class="upload-main">
              <span class="upload-btn">
                <i class="el-icon-upload"></i>上传apk包配置文件
              </span>
              上传apk包配置文件
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
import sysManage from "../../api/modules/sysManage";

import {
  equipType,
  deviceStates,
  generationList,
} from "../../config/dataStatus";
import { organizationCascader, phoneReg } from "../../utils/tool";
import organizationTree from "../../api/modules/organizationTree";

export default {
  name: "deviceTermiEdit",
  components: { CustomDialog },
  props: ["visible", "title", "item"],
  data() {
    return {
      editForm: {
        orgaId: "", //机构id，有企业选企业，有公司选公司
        orgaIdArr: [],
        projId: "",
        deviceNote: "",
      },
      rules: {},
      uploadUrl: config.uploadUrl,

      equipTypeList: equipType,
      deviceStateList: deviceStates,
      parentList: [],
      copanyListInfo: [],
      projectList: [],
      generationList: generationList,
      // equipType: equipType
    };
  },
  watch: {
    item(newVal, oldVal) {
      if (newVal.deviceId) {
        this.editForm = newVal;
      } else {
        this.editForm = {
          deviceImei: "",
          deviceType: "",
          deviceVersion: "",
          deviceState: 1,
          orgaIdArr: "",
        };
      }
      //   this.resetFields()
    },
  },
  mounted() {
    console.log('config.uploadUrl',config.uploadUrl);
    
    // this.getCopanyInfo();
    this.getProjectList();
  },
  methods: {
    uploadBefore(file) {
      // 在上传之前执行任何必要的检查或验证
      console.log("上传前：", file);
      //  this.fileName =file.File.name

      // 返回false以阻止自动上传
      //  return false;
    },

    uploadSuccess(response, file, fileList) {
      // 处理上传成功事件
      console.log("上传成功：", response,file);
      if (response.code == 200) {
        this.$message({
          message: response.message,
          type: "success",
        });
      }
    //   this.getRoleInfo();
    },

    colseDialog() {
      this.$emit("close");
    },
    getProjectList() {
      sysManage.GetProjectList().then((res) => {
        console.log("项目列表", res);
        res.data.map((item) => {
          this.projectList.push({
            label: item.projName,
            value: item.projId,
          });
        });
      });
    },
    getCopanyInfo() {
      organizationTree.getOrganizationTree().then((res) => {
        // 递归遍历 生成 {value, label, children}
        let data = organizationCascader(res.data);
        this.copanyListInfo = data;
      });
    },
    cascaderEditChange(val) {
      let valLen = val.length;
      this.editForm.orgaId = val[valLen - 1];
    },
    //获取摸块下拉框
    getModule() {
      sysManage.getModuleList({ moduleType: 0 }).then((res) => {
        console.log("res", res);
        this.parentList = [...res.data];
      });
    },
    submit() {
      this.$refs.editVersionForm.validate((valid) => {
        if (valid) {
          if (this.editForm.deviceId) {
            sysManage.editTerminal(this.editForm).then((res) => {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.$emit("done");
            });
          } else {
            sysManage.addTerminal(this.editForm).then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.$emit("done");
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
</style>
