<template>
  <el-dialog
      class="custom-dialog"
      :title="title"
      :visible.sync="visible"
      :width="width || '60%'"
      append-to-body
      :before-close="handleClose">
    <div slot="title" class="dialog-title">{{title}} &nbsp;</div>
    <slot></slot>
    <div slot="footer" class="dialog-footer">
    
      <el-button class="dialog-btn sure"  size="medium"   @click="submit" v-if="!showEdit"   type="success">保 存</el-button>
      <el-button class="dialog-btn del" size="medium" @click="handleClose"   type="success">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ['visible', 'width','title','showEdit'],
  name: "CustomDialog",
  methods:{
    handleClose(){
      this.$emit('close')
    },
    submit(){
      this.$emit('submit')
    }
  }
}
</script>

<style scoped lang="less">
.dialog-title{
  font-weight: bolder;
}
.dialog-btn{
  padding: 10px 35px;
}
.sure{
  background-color: #008f4d;
  color: #ffffff;
}
.del:hover{
  color: #008f4d;
  background-color: #ffffff;
  border:1px solid #008f4d;
}
.del{
  color: #008f4d;
  background-color: #ffffff;
  border:1px solid #008f4d;
}
</style>