<template>
    <div class="pageMain">
      <search-head :model="searchForm" @search="search" @reset="reset">
        <el-form :inline="true" :model="searchForm">
          <el-form-item label="主机IMEI">
            <el-input
              v-model="searchForm.deviceImei"
              size="small"
              placeholder="主机IMEI"
            ></el-input>
          </el-form-item>
          <el-form-item label="使用状态">
            <el-select
              v-model="searchForm.deviceState"
              size="small"
              placeholder="使用状态"
            >
              <el-option label="使用" value="0"></el-option>
              <el-option label="闲置" value="1"></el-option>
              <el-option label="损坏" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目名称">
            <el-input
              v-model="searchForm.projName"
              size="small"
              placeholder="项目名称"
            ></el-input>
          </el-form-item>
        </el-form>
      </search-head>
      <custom-tables
        :data="tableData"
        :headerCellStyle="headerCellStyle"
        @selectionChange="handleSelectionChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      
        :total="total"
        title="终端备案"
      >
        <template slot="btns">
          <el-button
          type="success"
            size="small"
            @click="addFrom"
            icon="el-icon-plus"
            class="addBtn"
            >新增</el-button
          >
          
        </template>
        <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="versionName"
          align="center"
          label="版本名称"
         >
      </el-table-column>
      <el-table-column
          prop="versionNumbser"
          align="center"
          label="版本号"
       >
      </el-table-column>
      <el-table-column
          prop="createTime"
          align="center"
          label="上传时间">
      </el-table-column>
      </custom-tables>
      <sixUp
        :visible.sync="showDialog"
        @close="colseDialog"
        :title="title"
        @done="doneDialog"
        :item="item"
      >
      </sixUp>
      
    </div>
  </template>
  
  <script>
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import sixUp from "../../../components/systemManage/sixUp.vue";

  import sysManage from "../../../api/modules/sysManage";
  export default {
    name: "index",
    components: { sixUp, CustomTables, SearchHead, },
    data() {
      return {
        tableData: [],
        searchForm: {
          currPage: 1,
          pageSize: 10,
        },
        total: 0,
        showDialog: false,
        showDialog2: false,
        headerCellStyle: {
          backgroundColor: "#f3f2f7",
          color: "#373628",
        },
        title:'',
        number:false,
        item:{}
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList() {
        sysManage.listAllSix(this.searchForm).then((res) => {
          this.total = res.data.total;
          this.tableData = res.data
        });
      },
      search() {
        console.log("searchForm", this.searchForm);
        this.searchForm.currPage = 1;
        this.searchForm.pageSize = 10;
        this.getList();
      },
      reset() {
        this.searchForm = {
          currPage: 1,
          pageSize: 10,
        };
        this.getList();
      },
      addFrom() {
        this.item={}
        this.showDialog = true;
        this.title='新增终端'
      },
      addFrom2() {
        this.item={}
        this.number=false
        this.showDialog2 = true;
        this.title='批量新增终端'
      },
      colseDialog() {
        this.showDialog = false;
      },
      doneDialog(){
        this.showDialog = false;
        this.getList();
      },
      colseDialog2() {
        this.showDialog2 = false;
      },
      doneDialog2(){
        this.showDialog2 = false;
        this.getList();
      },
      submit() {
        this.showDialog = false;
      },
      detailItem(item) {
        //详情
        if(item.generation=='5'){
          this.item=item
        this.showDialog = true;
        this.number=false
        this.title='编辑终端'
        }else if(item.generation=='6'){
          this.item=item
        this.showDialog2 = true;
          this.number=true
        this.title='编辑终端'
        }
       
      },
      deleteItem(item) {
        console.log(item, 'item');
        this.$confirm(`此操作将永久删除设备${item.deviceImei}, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          //{staffId:[item.staffId]}
          //  return
          sysManage.delTerminal(item.deviceId).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getList();
          });
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        })
      },
      handleSelectionChange(val) {
        console.log(val);
      },
      handleCurrentChange(pageIndex) {
        this.searchForm.currPage = pageIndex
        this.getList()
      },
      handleSizeChange(pageSize) {
        this.searchForm.pageSize = pageSize
        this.searchForm.currPage = 1
        this.getList()
      },
    },
  };
  </script>
  
  <style scoped lang="less">
  .detail {
    color: #008f4d;
  }
  .delete {
    margin-left: 10px;
  }
  .addBtn {
    background-color: #008f4d;
    color: #fff;
  }
  .btn {
    background-color: #9054e8;
    color: #ffffff;
    // color: #008f4d;
  }
  .btn:hover {
    background-color: #7714a6;
  }
  </style>
  