import api from '../httpServer'
import config from '../config'
// import store from '@/store/index'

const sysManage ={
    //获取模块列表
    getModuleList(data) {
        return api.get(`${config.headerUrl}modulelist/getModuleList`,data)
    },
    //新增模块信息
    addModuleList(data) {
        return api.post(`${config.headerUrl}modulelist/addModuleList`,data)
    },
     //编辑模块信息
     editModuleList(data) {
        return api.post(`${config.headerUrl}modulelist/editModuleList`,data)
    },
     //删除模块信息
     deleteModuleList(data) {
        return api.post(`${config.headerUrl}modulelist/deleteModuleList`,data)
    },
    //模块信息分页列表
    getModuleListByPage(data) {
        return api.get(`${config.headerUrl}modulelist/getModuleListByPage`,data)
    },
     //角色分页列表
     getListByPage(data) {
        return api.get(`${config.headerUrl}role/getListByPage`,data)
    },
     //新增角色信息
     addRole(data) {
        return api.post(`${config.headerUrl}role/addRole`,data)
    },
     //修改角色信息
     editRole(data) {
        return api.post(`${config.headerUrl}role/editRole`,data)
    },
     //删除角色信息
     deleteRole(data) {
        return api.post(`${config.headerUrl}role/deleteRole`,data)
    },
     // 角色列表
     getRoleList(data) {
        return api.get(`${config.headerUrl}role/getRoleList`,data)
    },
      // 获取角色功能权限列表
      getRoleModuleList(data) {
        return api.get(`${config.headerUrl}roleAuth/getRoleModuleList`,data)
    },
     // 获取授权模块
     getTypeAndChildren(data) {
        return api.get(`${config.headerUrl}modulelist/getTypeAndChildren`,data)
    },
      /*
    * 角色权限
    * */
      addRoleAuth(data) {
        return api.post(`${config.headerUrl}roleAuth/addRoleAuth`,data)
    },
    //获取用户角色分页列表
    getStaffRoleListByPage(data) {
        return api.get(`${config.headerUrl}staffrole/getStaffRoleListByPage`,data)
    },
    // 查询管理人员选择列表
    selectStaffList(data) {
        return api.get(`${config.headerUrl}staff/selectStaffList`,data)
    },
    //新增用户角色
    addStaffrole(data) {
        return api.post(`${config.headerUrl}staffrole/addStaffrole`,data)
    },
     //新增用户角色
     addStaffrole(data) {
        return api.post(`${config.headerUrl}staffrole/addStaffrole`,data)
    },
     //删除用户角色
     deleteStaffrole(data) {
        return api.post(`${config.headerUrl}staffrole/deleteStaffrole`,data)
    },
    //组织机构的分页查询
    GetOrganizationList(data) {
        return api.get(`${config.headerUrl}organization/GetOrganizationList`,data)
    },
    //版本发布分页查询
    getVersionListByPage(data) {
        return api.get(`${config.headerUrl}deviceVer/getVersionListByPage`,data)
    },
    //版本新增
    addDeviceVer(data) {
        return api.post(`${config.headerUrl}deviceVer/addDeviceVer`,data)
    },
    //删除版本
    delVersion(data) {
        return api.post(`${config.headerUrl}programversion/del/${data}`,data)
     },
    //查询管理人员选择列表
    selectStaff(data) {
        return api.get(`${config.headerUrl}staff/selectStaff`,data)
    },
    //新增人员
    newStaff(data) {
        return api.post(`${config.headerUrl}staff/newStaff`,data)
    },
    //编辑人员
    updateStaff(data) {
        return api.post(`${config.headerUrl}staff/updateStaff`,data)
    },
    //删除人员
    delStaff(data) {
        return api.post(`${config.headerUrl}staff/delStaff`,data)
    },
    //分页查询终端备案列表
    getTerminalListByPage(data) {
        return api.get(`${config.headerUrl}device/terminal/getTerminalListAllByPage`,data)
    },
    //新增终端备案
    addTerminal(data) {
        return api.post(`${config.headerUrl}device/terminal/addTerminal`,data)
    },
       //批量新增终端备案
       addBatchTerminal(data) {
        return api.post(`${config.headerUrl}device/terminal/addBatchTerminal`,data)
    },
       //编辑终端备案
       editTerminal(data) {
        return api.post(`${config.headerUrl}device/terminal/editTerminal`,data)
    },
     //删除人员
     delTerminal(data) {
        return api.get(`${config.headerUrl}device/terminal/delTerminal/${data}`,data)
    },
    //分页查询批量升级
    getDeviceVersionPage(data) {
        return api.get(`${config.headerUrl}deviceversion/getDeviceVersionPage`,data)
    },
    //分页查询视频备案列表
    getCameraListByPage(data) {
        return api.get(`${config.headerUrl}camera/getCameraListByPage`,data)
    },
    //新增视频备案
    addCamera(data) {
        return api.post(`${config.headerUrl}camera/addCamera`,data)
    },
      //删除视频备案
      delCamera(data) {
        return api.get(`${config.headerUrl}camera/delCamera/${data}`,data)
    },
       //获取项目选择列表
       GetProjectList(data) {
        return api.get(`${config.headerUrl}basicSet/GetProjectList`,data)
    },
    //根据公司查项目
    selectOrgaIdProjname(data) {
            return api.get(`${config.headerUrl}company/selectOrgaIdProjname`,data)
        },
     //项目和设备类型查设备
     getProjDeviceAll(data) {
        return api.get(`${config.headerUrl}base/equip/getProjDeviceAll`,data)
    },
    // 获取历史版本选择列表
    getHisUpdateVer(data) {
        return api.post(`${config.headerUrl}deviceVer/getHisUpdateVerList`, data)
    },
     // 批量升级增加设备
     addNeedDeviceUpdate(data) {
        return api.post(`${config.headerUrl}deviceVer/addNeedDeviceUpdateBatch`, data)
    },
       // 批量查询-分页查询
       getBatchVerLogListByPage(data) {
        return api.get(`${config.headerUrl}deviceVer/getBatchVerLogListByPage`, data)
    },
     // 硬件传输接口-修改配置
     updateTerminal(data) {
        return api.post(`${config.headerUrl}mqtt/updateTerminal`, data)
    },
       // 单个升级-分页查询
       getVerLogListByPage(data) {
        return api.get(`${config.headerUrl}deviceVer/getVerLogListByPage`, data)
    },
        // 分页查询小程序人员列表
        getAppStaffListByPage(data) {
            return api.get(`${config.headerUrl}appstaff/getAppStaffListByPage`, data)
        },
         // 分页查询小程序人员新增
         addAppStaff(data) {
            return api.post(`${config.headerUrl}appstaff/addAppStaff`, data)
        },
         // 小程序人员编辑
         editAppStaff(data) {
            return api.post(`${config.headerUrl}appstaff/editAppStaff`, data)
        },
         // 小程序人员删除
         delAppStaff(data) {
            return api.get(`${config.headerUrl}appstaff/delAppStaff/${data}`,data)
        },
}

export default sysManage