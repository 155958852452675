/*
* 转换时间的
*
* */
export function turnTime(value) {
    let date = new Date(value);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? "0" + MM : MM;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let m = date.getMinutes();
    m = m < 10 ? "0" + m : m;
    let s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
}
export function turnDayTime(value) {
    let date = new Date(value);
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let m = date.getMinutes();
    m = m < 10 ? "0" + m : m;
    let s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    return h + ':' + m + ':' + s;
}
export function turnTimes(value) {
    let date = new Date(value *1000);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? "0" + MM : MM;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let m = date.getMinutes();
    m = m < 10 ? "0" + m : m;
    let s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
}
/*
* 类型判断
* */
export const checkType = val => Object.prototype.toString.call(val).slice(8, -1)

/*
* 判断价格
* */
export function priceReg(val){
    let reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
    return reg.test(val)
}
/*
* 下载，导出文档
* */
export function downloadFile (res) {
    const blob = new Blob([res.data]);
    const a = document.createElement('a');
    const href = window.URL.createObjectURL(blob); // 下载的链接
    a.href = href;
    a.download = decodeURI(
        res.headers['content-disposition'].split(';')[1].split('=')[1]
    ); // 获取后台设置的文件名称
    document.body.appendChild(a);
    a.click(); // 点击导出
    document.body.removeChild(a); // 下载完成移除元素
    window.URL.revokeObjectURL(href); // 释放掉blob对象
    // return file
}
/*
* 组织机构树--生成为cascader可用
* return {value, label, children}
* */
export function organizationCascader(dataList, noProject){
   
    return dataList.map((item) =>{
         let get = (data) =>{
             return  data.map(child =>{
                 let children
                 if(noProject && child.orgaLevel >= 1) {
                     children = null
                  
                 }else {
                     children = child.children && child.children.length > 0 ? get(child.children) : null
                     
                 }
                 return {
                    
                     value: child.orgaId,
                     label: child.orgaName,
                     orgaPathIds: child.orgaPathIds,
                     level: child.orgaLevel,
                     children: children
                 }
             })
         }
 
        let childrenFirst
        if (noProject && item.orgaLevel >= 1) {
            childrenFirst = null
        }else {
            childrenFirst = item.children && item.children.length > 0 ? get(item.children) : null
        }
         return {
             value: item.orgaId,
             label:item.orgaName,
             orgaPathIds: item.orgaPathIds,
             level: item.orgaLevel,
             children: childrenFirst
         }
     })
 }
 export function organizationCascaders(dataList, noProject, noChildren){
    return dataList.map((item) =>{
         let get = (data) =>{
             return  data.map(child =>{
                 let children
                 if(noChildren || (noProject && child.orgaLevel <= 1)) {
                     children = null
                 }else {
                     children = child.children && child.children.length > 0 ? get(child.children) : null
                 }
                 return {
                     value: child.orgaId,
                     label: child.orgaName,
                     orgaPathIds: child.orgaPathIds,
                     level: child.orgaLevel,
                     children: children
                 }
             })
         }
        let childrenFirst
        if (noChildren || (noProject && item.orgaLevel <= 1)) {
            childrenFirst = null
        }else {
            childrenFirst = item.children && item.children.length > 0 ? get(item.children) : null
        }
         return {
             value: item.orgaId,
             label:item.orgaName,
             orgaPathIds: item.orgaPathIds,
             level: item.orgaLevel,
             children: childrenFirst
         }
     })
 }
 /*
* 判断手机,11位
* */
export function phoneReg(val) {
    let reg = /^1[345678]\d{9}$/;
    return reg.test(val)
}
//时长
export function toDuration(value) {
    if (!value) {
        return 'N/A'
    }
    let S = Math.floor(value % 60);
    let M = Math.floor((value / 60) % 60);
    let H = Math.floor((value / 60 / 60) % 60);
    let D = Math.floor((value / 60 / 60 / 60) % 60);
    return `${D > 0 ? D + '天' : ''}${H > 0 ? H + '时' : ''}${M > 0 ? M + '分' : ''}${S > 0 ? S + '秒' : ''}`
}